import React, { useEffect, useState } from 'react';
import AdminSidebar from '../../components/AdminSidebar';
import AdminHeader from '../../components/AdminHeader';
import { collection, getDocs } from 'firebase/firestore';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';
import { db } from '../../firebase';

const AdminReportsRestaurants = () => {
  const [topRestaurants, setTopRestaurants] = useState([]);
  const [topDeliveryFees, setTopDeliveryFees] = useState([]);

  useEffect(() => {
    const fetchTopRestaurants = async () => {
      const snapshot = await getDocs(collection(db, 'orders'));
      const orders = snapshot.docs.map(doc => doc.data());
  
      const countMap = {};
      const feeMap = {};
  
      orders.forEach(order => {
        const name = order.restaurantName || 'غير معروف';
        countMap[name] = (countMap[name] || 0) + 1;
        feeMap[name] = (feeMap[name] || 0) + (order.deliveryFee || 0);
      });
  
      const topCount = Object.entries(countMap)
        .map(([name, count]) => ({ name, count }))
        .sort((a, b) => b.count - a.count)
        .slice(0, 5);
  
      const topFees = Object.entries(feeMap)
        .map(([name, totalFee]) => ({ name, totalFee: parseFloat(totalFee.toFixed(3)) }))
        .sort((a, b) => b.totalFee - a.totalFee)
        .slice(0, 5);
  
      setTopRestaurants(topCount);
      setTopDeliveryFees(topFees);
    };
  
    fetchTopRestaurants();
  }, []);
  

  return (
    <div className="restaurant-dashboard">
      <AdminSidebar />
      <div className="restaurant-dashboard-content">
        <AdminHeader adminName="تقارير المطاعم" />

        <div className="restaurant-dashboard-welcome">
          <h1>تقارير المطاعم</h1>
          <p>هنا ستظهر الرسوم البيانية والإحصائيات الخاصة بالمطاعم</p>
        </div>

        <div className="report-chart-section">
  <h3>أكثر المطاعم من حيث رسوم التوصيل (د.ك)</h3>
  <ResponsiveContainer width="100%" height={300}>
    <BarChart data={topDeliveryFees} layout="vertical" margin={{ left: 30 }}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis type="number" />
      <YAxis dataKey="name" type="category" width={120} />
      <Tooltip />
      <Bar dataKey="totalFee" fill="#28a745" barSize={24} />
    </BarChart>
  </ResponsiveContainer>
</div>


        {/* ✅ الرسم البياني */}
        <div className="report-chart-section">
          <h3>أكثر المطاعم نشاطًا (حسب عدد الطلبات)</h3>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={topRestaurants} layout="vertical" margin={{ left: 30 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" allowDecimals={false} />
              <YAxis dataKey="name" type="category" width={120} />
              <Tooltip />
              <Bar dataKey="count" fill="#4a90e2" barSize={24} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default AdminReportsRestaurants;
