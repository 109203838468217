// src/components/AdminHeader.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs, orderBy, limit } from 'firebase/firestore';
import { db } from '../firebase';
import './RestaurantHeader.css'; // إعادة استخدام نفس التنسيق

const AdminHeader = ({ adminName }) => {
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const navigate = useNavigate();
  const adminId = localStorage.getItem('adminId'); // تأكد أنك تحفظه عند تسجيل الدخول

  useEffect(() => {
    const fetchNotifications = async () => {
      if (!adminId) return;

      try {
        const notificationsRef = collection(db, 'notifications');
        const q = query(
          notificationsRef,
          where('recipientId', '==', adminId),
          where('recipientType', '==', 'admin'),
          orderBy('timestamp', 'desc'),
          limit(5)
        );
        const querySnapshot = await getDocs(q);
        const notificationsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          formattedTime: doc.data().timestamp ? formatTimestamp(doc.data().timestamp) : ''
        }));

        setNotifications(notificationsData);
        setUnreadCount(notificationsData.filter(n => !n.isRead).length);
      } catch (error) {
        console.error('❌ خطأ في جلب إشعارات الأدمن:', error);
      }
    };

    fetchNotifications();
    const intervalId = setInterval(fetchNotifications, 60000);
    return () => clearInterval(intervalId);
  }, [adminId]);

  const formatTimestamp = (timestamp) => {
    const date = timestamp.toDate();
    const now = new Date();
    const diffMs = now - date;
    const diffMins = Math.round(diffMs / 60000);

    if (diffMins < 1) return 'الآن';
    if (diffMins < 60) return `منذ ${diffMins} دقيقة`;

    const diffHours = Math.floor(diffMins / 60);
    if (diffHours < 24) return `منذ ${diffHours} ساعة`;

    const diffDays = Math.floor(diffHours / 24);
    if (diffDays < 30) return `منذ ${diffDays} يوم`;

    return date.toLocaleDateString('ar-EG');
  };

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  return (
    <div className="restaurant-header">
      <div className="restaurant-header-title">
        <h2>{adminName}</h2>
      </div>

      <div className="restaurant-header-actions">
        <div className="restaurant-notifications">
          <button className="notification-button" onClick={toggleNotifications}>
            <span className="notification-icon">🔔</span>
            {unreadCount > 0 && <span className="notification-badge">{unreadCount}</span>}
          </button>

          {showNotifications && (
            <div className="notifications-dropdown">
              <div className="notifications-header">
                <h3>الإشعارات</h3>
                {notifications.length > 0 && (
                  <button className="see-all-button" onClick={() => navigate('/notifications')}>
                    عرض الكل
                  </button>
                )}
              </div>

              <div className="notifications-list">
                {notifications.length === 0 ? (
                  <div className="empty-notifications">
                    <p>لا توجد إشعارات</p>
                  </div>
                ) : (
                  notifications.map(notification => (
                    <div 
                      key={notification.id} 
                      className={`notification-item ${!notification.isRead ? 'unread' : ''}`}
                    >
                      <div className="notification-content">
                        <p>{notification.message}</p>
                        <span className="notification-time">{notification.formattedTime}</span>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          )}
        </div>

        <div className="restaurant-profile">
          <div className="profile-avatar">{adminName?.charAt(0) || 'أ'}</div>
        </div>
      </div>
    </div>
  );
};

export default AdminHeader;
