// src/utils/locationUtils.js

// إحداثيات الكويت الافتراضية
export const DEFAULT_KUWAIT_LOCATION = { lat: 29.3759, lng: 47.9774 };

/**
 * التحقق مما إذا كان كائن الموقع يحتوي على إحداثيات صالحة
 */
export const isValidCoordinate = (location) => {
  return location 
    && typeof location.lat === 'number' 
    && typeof location.lng === 'number'
    && !isNaN(location.lat)
    && !isNaN(location.lng)
    && location.lat !== 0
    && location.lng !== 0
    && location.lat >= -90 && location.lat <= 90  // تحقق إضافي من صحة خطوط العرض
    && location.lng >= -180 && location.lng <= 180;  // تحقق إضافي من صحة خطوط الطول
};

/**
 * الحصول على الموقع الحالي للمستخدم
 */
export const getCurrentLocation = () => {
  return new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      console.log("الجهاز لا يدعم تحديد الموقع الجغرافي");
      return resolve(DEFAULT_KUWAIT_LOCATION);
    }
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const location = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        console.log("تم الحصول على الموقع الحالي:", location);
        resolve(location);
      },
      (error) => {
        console.error("فشل الحصول على الموقع الحالي:", error);
        resolve(DEFAULT_KUWAIT_LOCATION);
      },
      { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
    );
  });
};

/**
 * إرجاع موقع صالح أو الموقع الافتراضي للكويت
 */
export const getValidLocation = (location) => {
  return isValidCoordinate(location) ? location : DEFAULT_KUWAIT_LOCATION;
};