// src/admin/pages/AdminSettings.js
import React, { useState } from 'react';
import AdminSidebar from '../../components/AdminSidebar';
import AdminHeader from '../../components/AdminHeader';
import './AdminSettings.css';

const AdminSettings = () => {
  const [adminName, setAdminName] = useState('Admin');
  const [email, setEmail] = useState('admin@example.com');
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleSave = () => {
    setMessage('✅ تم حفظ التغييرات بنجاح');
    // بإمكانك تنفيذ عملية تحديث البيانات من Firebase هنا لاحقًا
  };

  return (
    <div className="restaurant-dashboard">
      <AdminSidebar />
      <div className="restaurant-dashboard-content">
        <AdminHeader adminName="إعدادات الأدمن" />

        <div className="restaurant-dashboard-welcome">
          <h1>إعدادات الحساب</h1>
          <p>قم بتحديث بياناتك الخاصة من هنا</p>
        </div>

        <div className="admin-settings-form">
          <label>الاسم</label>
          <input type="text" value={adminName} onChange={(e) => setAdminName(e.target.value)} />

          <label>البريد الإلكتروني</label>
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />

          <label>كلمة المرور الجديدة</label>
          <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />

          <button onClick={handleSave}>حفظ التغييرات</button>
          {message && <p className="success-message">{message}</p>}
        </div>
      </div>
    </div>
  );
};

export default AdminSettings;
