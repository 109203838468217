// src/App.js
import React, { useEffect } from "react";
import { requestNotificationPermission, listenForMessages } from "./utils/notifications";
import AppRouter from "./Router";
import { GoogleMapsProvider } from "./components/GoogleMapsProvider";
import ErrorBoundary from "./components/ErrorBoundary";

import "./App.css";

const App = () => {
  useEffect(() => {
    // محاولة طلب إذن الإشعارات عند بدء التطبيق
    const setupNotifications = async () => {
      try {
        await requestNotificationPermission();
        listenForMessages();
      } catch (error) {
        console.error("❌ خطأ في إعداد الإشعارات:", error);
      }
    };
    
    setupNotifications();
  }, []);

  return (
    <ErrorBoundary>
      <GoogleMapsProvider>
        <AppRouter />
      </GoogleMapsProvider>
    </ErrorBoundary>
  );
};

export default App;