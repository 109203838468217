import React, { useState, useEffect } from 'react';
import AdminSidebar from '../../components/AdminSidebar';
import AdminHeader from '../../components/AdminHeader';
import FinanceFilters from '../../components/FinanceFilters';
import './AdminReportsFinance.css';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

const AdminReportsFinance = () => {
  const [activeTab, setActiveTab] = useState('restaurants');

  const [restaurantFilters, setRestaurantFilters] = useState({
    dateRange: 'month',
    name: '',
    status: 'all',
  });

  const [driverFilters, setDriverFilters] = useState({
    dateRange: 'month',
    name: '',
    status: 'all',
  });

  const [restaurantStats, setRestaurantStats] = useState([]);
  const [driverStats, setDriverStats] = useState([]);

  // ✅ Fetch restaurant data
  useEffect(() => {
    const fetchRestaurantData = async () => {
      try {
        const snapshot = await getDocs(collection(db, 'orders'));
        const allOrders = snapshot.docs.map(doc => doc.data());

        const filtered = allOrders.filter(order => {
          if (!order.createdAt) return false;

          const nameMatch = restaurantFilters.name === '' ||
            (order.restaurantName && order.restaurantName.includes(restaurantFilters.name));

          const statusMatch = restaurantFilters.status === 'all' ||
            order.status === restaurantFilters.status;

          const date = order.createdAt.toDate();
          const now = new Date();
          let dateMatch = true;

          if (restaurantFilters.dateRange === 'day') {
            dateMatch =
              date.getDate() === now.getDate() &&
              date.getMonth() === now.getMonth() &&
              date.getFullYear() === now.getFullYear();
          } else if (restaurantFilters.dateRange === 'month') {
            dateMatch =
              date.getMonth() === now.getMonth() &&
              date.getFullYear() === now.getFullYear();
          } else if (restaurantFilters.dateRange === 'year') {
            dateMatch = date.getFullYear() === now.getFullYear();
          }

          return nameMatch && statusMatch && dateMatch;
        });

        const grouped = {};
        filtered.forEach(order => {
          const name = order.restaurantName || 'غير معروف';
          if (!grouped[name]) {
            grouped[name] = { name, count: 0, total: 0 };
          }
          grouped[name].count += 1;
          grouped[name].total += order.deliveryFee || 0;
        });

        setRestaurantStats(Object.values(grouped));
      } catch (error) {
        console.error('❌ خطأ في جلب بيانات التقارير المالية للمطاعم:', error);
      }
    };

    fetchRestaurantData();
  }, [restaurantFilters]);

  // ✅ Fetch driver data
  useEffect(() => {
    const fetchDriverData = async () => {
      try {
        const snapshot = await getDocs(collection(db, 'orders'));
        const allOrders = snapshot.docs.map(doc => doc.data());

        const filtered = allOrders.filter(order => {
          if (!order.createdAt) return false;

          const nameMatch = driverFilters.name === '' ||
            (order.driverName && order.driverName.includes(driverFilters.name));

          const statusMatch = driverFilters.status === 'all' ||
            order.status === driverFilters.status;

          const date = order.createdAt.toDate();
          const now = new Date();
          let dateMatch = true;

          if (driverFilters.dateRange === 'day') {
            dateMatch =
              date.getDate() === now.getDate() &&
              date.getMonth() === now.getMonth() &&
              date.getFullYear() === now.getFullYear();
          } else if (driverFilters.dateRange === 'month') {
            dateMatch =
              date.getMonth() === now.getMonth() &&
              date.getFullYear() === now.getFullYear();
          } else if (driverFilters.dateRange === 'year') {
            dateMatch = date.getFullYear() === now.getFullYear();
          }

          return nameMatch && statusMatch && dateMatch;
        });

        const grouped = {};
        filtered.forEach(order => {
          const name = order.driverName || 'غير معروف';
          if (!grouped[name]) {
            grouped[name] = { name, count: 0, total: 0 };
          }
          grouped[name].count += 1;
          grouped[name].total += order.deliveryFee || 0;
        });

        setDriverStats(Object.values(grouped));
      } catch (error) {
        console.error('❌ خطأ في جلب بيانات تقارير السائقين:', error);
      }
    };

    fetchDriverData();
  }, [driverFilters]);

  return (
    <div className="restaurant-dashboard">
      <AdminSidebar />
      <div className="restaurant-dashboard-content">
        <AdminHeader adminName="التقارير المالية" />

        <div className="restaurant-dashboard-welcome">
          <h1>التقارير المالية</h1>
          <p>تحليلات مالية للمطاعم والسائقين حسب الفلاتر المختارة</p>
        </div>

        {/* Tabs */}
        <div className="finance-tabs">
          <button className={activeTab === 'restaurants' ? 'active' : ''} onClick={() => setActiveTab('restaurants')}>
            تقارير المطاعم
          </button>
          <button className={activeTab === 'drivers' ? 'active' : ''} onClick={() => setActiveTab('drivers')}>
            تقارير السائقين
          </button>
        </div>

        {/* Filters */}
        <FinanceFilters
          filters={activeTab === 'restaurants' ? restaurantFilters : driverFilters}
          onChange={activeTab === 'restaurants' ? setRestaurantFilters : setDriverFilters}
        />

        {/* Charts */}
        {activeTab === 'restaurants' ? (
          <>
            <div className="report-chart-section">
              <h3>عدد الطلبات لكل مطعم</h3>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={restaurantStats} layout="vertical" margin={{ left: 30 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" allowDecimals={false} />
                  <YAxis dataKey="name" type="category" width={120} />
                  <Tooltip formatter={(value) => `${value} طلب`} />
                  <Bar dataKey="count" fill="#4a90e2" barSize={24} />
                </BarChart>
              </ResponsiveContainer>
            </div>

            <div className="report-chart-section">
              <h3>إجمالي رسوم التوصيل لكل مطعم (د.ك)</h3>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={restaurantStats} layout="vertical" margin={{ left: 30 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" />
                  <YAxis dataKey="name" type="category" width={120} />
                  <Tooltip formatter={(value) => `${value.toFixed(3)} د.ك`} />
                  <Bar dataKey="total" fill="#28a745" barSize={24} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </>
        ) : (
          <>
            <div className="report-chart-section">
              <h3>عدد الطلبات لكل سائق</h3>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={driverStats} layout="vertical" margin={{ left: 30 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" allowDecimals={false} />
                  <YAxis dataKey="name" type="category" width={120} />
                  <Tooltip formatter={(value) => `${value} طلب`} />
                  <Bar dataKey="count" fill="#4a90e2" barSize={24} />
                </BarChart>
              </ResponsiveContainer>
            </div>

            <div className="report-chart-section">
              <h3>إجمالي رسوم التوصيل لكل سائق (د.ك)</h3>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={driverStats} layout="vertical" margin={{ left: 30 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" />
                  <YAxis dataKey="name" type="category" width={120} />
                  <Tooltip formatter={(value) => `${value.toFixed(3)} د.ك`} />
                  <Bar dataKey="total" fill="#28a745" barSize={24} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AdminReportsFinance;
