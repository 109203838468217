import React from 'react';
import { useNavigate } from 'react-router-dom';
import AdminSidebar from '../../components/AdminSidebar';
import AdminHeader from '../../components/AdminHeader';
import './AdminReports.css';

const AdminReports = () => {
  const navigate = useNavigate();

  return (
    <div className="restaurant-dashboard">
      <AdminSidebar />
      <div className="restaurant-dashboard-content">
        <AdminHeader adminName="تقارير النظام" />

        <div className="restaurant-dashboard-welcome">
          <h1>أقسام التقارير</h1>
          <p>اختر القسم الذي ترغب بعرض تقاريره التفصيلية</p>
        </div>

        <div className="restaurant-dashboard-actions">
          <div className="action-card" onClick={() => navigate('/admin/reports/restaurants')}>
            <div className="action-icon">🏪</div>
            <h3>تقارير المطاعم</h3>
            <p>عرض نشاط المطاعم وتحليل الطلبات</p>
          </div>

          <div className="action-card" onClick={() => navigate('/admin/reports/drivers')}>
            <div className="action-icon">🚗</div>
            <h3>تقارير السائقين</h3>
            <p>متابعة أداء السائقين ونشاطهم</p>
          </div>

          <div className="action-card" onClick={() => navigate('/admin/reports/finance')}>
            <div className="action-icon">💰</div>
            <h3>تقارير مالية</h3>
            <p>تحليل أرباح ورسوم التوصيل</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminReports;
