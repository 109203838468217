import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './RestaurantSidebar.css'; // ✅ نستخدم نفس التنسيق

const AdminSidebar = () => {
  const location = useLocation();
  const isActive = (path) => location.pathname === path;

  return (
    <div className="restaurant-sidebar">
      <div className="restaurant-sidebar-header">
        <h2>AT Delivery</h2>
        <p>لوحة الأدمن</p>
      </div>

      <div className="restaurant-sidebar-menu">
        <Link to="/dashboard" className={`menu-item ${isActive('/dashboard') ? 'active' : ''}`}>
          <span className="menu-icon">🏠</span>
          <span className="menu-text">الرئيسية</span>
        </Link>

        <Link to="/admin/restaurant-management" className={`menu-item ${isActive('/admin/restaurant-management') ? 'active' : ''}`}>
          <span className="menu-icon">🏪</span>
          <span className="menu-text">إدارة المطاعم</span>
        </Link>

        <Link to="/admin/driver-management" className={`menu-item ${isActive('/admin/driver-management') ? 'active' : ''}`}>
          <span className="menu-icon">🚗</span>
          <span className="menu-text">إدارة السائقين</span>
        </Link>

        <Link to="/admin/orders" className={`menu-item ${isActive('/admin/orders') ? 'active' : ''}`}>
          <span className="menu-icon">📦</span>
          <span className="menu-text">الطلبات</span>
        </Link>
        <Link to="/admin/reports" className={`menu-item ${isActive('/admin/reports') ? 'active' : ''}`}>
  <span className="menu-icon">📊</span>
  <span className="menu-text">التقارير</span>
</Link>


        <Link to="/notifications" className={`menu-item ${isActive('/notifications') ? 'active' : ''}`}>
          <span className="menu-icon">🔔</span>
          <span className="menu-text">الإشعارات</span>
        </Link>

        <Link to="/admin/settings" className={`menu-item ${isActive('/admin/settings') ? 'active' : ''}`}>
  <span className="menu-icon">⚙️</span>
  <span className="menu-text">الإعدادات</span>
</Link>

      </div>

      <div className="restaurant-sidebar-footer">
        <button className="logout-button" onClick={() => {
          localStorage.clear();
          window.location.href = '/login';
        }}>
          <span className="logout-icon">🔒</span>
          تسجيل الخروج
        </button>
      </div>
    </div>
  );
};

export default AdminSidebar;
