// src/pages/Dashboard.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import AdminSidebar from '../components/AdminSidebar';
import AdminHeader from '../components/AdminHeader';
import './AdminDashboard.css';

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({
    restaurants: 0,
    drivers: 0,
    completedOrders: 0,
  });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const restaurants = await getDocs(collection(db, 'restaurants'));
        const drivers = await getDocs(collection(db, 'drivers'));
        const completedOrdersSnapshot = await getDocs(
          query(collection(db, 'orders'), where('status', '==', 'مكتمل'))
        );

        setStats({
          restaurants: restaurants.size,
          drivers: drivers.size,
          completedOrders: completedOrdersSnapshot.size,
        });
      } catch (error) {
        console.error('❌ فشل تحميل الإحصائيات:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, []);

  if (loading) {
    return (
      <div className="restaurant-loading">
        <div className="restaurant-spinner"></div>
        <p>جاري تحميل البيانات...</p>
      </div>
    );
  }

  return (
    <div className="restaurant-dashboard">
      <AdminSidebar />
      <div className="restaurant-dashboard-content">
        <AdminHeader adminName="لوحة تحكم الأدمن" />

        <div className="restaurant-dashboard-welcome">
          <h1>مرحبًا بك، أدمن!</h1>
          <p>إليك ملخص النظام ويمكنك التحكم الكامل من هنا.</p>
        </div>

        <div className="restaurant-dashboard-stats">
          <div className="stat-card">
            <div className="stat-icon orders-icon">🏪</div>
            <div className="stat-content">
              <h3>عدد المطاعم</h3>
              <p className="stat-number">{stats.restaurants}</p>
            </div>
          </div>

          <div className="stat-card">
            <div className="stat-icon completed-icon">🚗</div>
            <div className="stat-content">
              <h3>عدد السائقين</h3>
              <p className="stat-number">{stats.drivers}</p>
            </div>
          </div>

          <div className="stat-card">
            <div className="stat-icon today-icon">✅</div>
            <div className="stat-content">
              <h3>الطلبات المكتملة</h3>
              <p className="stat-number">{stats.completedOrders}</p>
            </div>
          </div>
        </div>

        <div className="restaurant-dashboard-actions">
          <div className="action-card" onClick={() => navigate('/admin/restaurant-management')}>
            <div className="action-icon">🏪</div>
            <h3>إدارة المطاعم</h3>
            <p>إضافة وتعديل المطاعم</p>
          </div>

          <div className="action-card" onClick={() => navigate('/admin/driver-management')}>
            <div className="action-icon">🚗</div>
            <h3>إدارة السائقين</h3>
            <p>إضافة وتتبع السائقين</p>
          </div>

          <div className="action-card" onClick={() => navigate('/admin/orders')}>
            <div className="action-icon">📦</div>
            <h3>الطلبات</h3>
            <p>عرض ومتابعة جميع الطلبات</p>
          </div> 
        
          <div className="action-card" onClick={() => navigate('/admin/reports')}>
            <div className="action-icon">📊</div>
            <h3>التقارير</h3>
            <p>عرض وتحليل الأداء والإحصائيات</p>
          </div>


          <div className="action-card" onClick={() => navigate('/notifications')}>
            <div className="action-icon">🔔</div>
            <h3>الإشعارات</h3>
            <p>إرسال أو تعديل التنبيهات</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
