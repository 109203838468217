import React from 'react';
import AdminSidebar from '../../components/AdminSidebar';
import AdminHeader from '../../components/AdminHeader';
import { useEffect, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';
import { db } from '../../firebase';

const AdminReportsDrivers = () => {
  const [topDrivers, setTopDrivers] = useState([]);
  const [topDriverFees, setTopDriverFees] = useState([]);

  useEffect(() => {
    const fetchTopDrivers = async () => {
      const snapshot = await getDocs(query(
        collection(db, 'orders'),
        where('status', '==', 'تم التسليم ✅')
      ));
      const orders = snapshot.docs.map(doc => doc.data());
  
      const countMap = {};
      const feeMap = {};
  
      orders.forEach(order => {
        const name = order.driverName || 'غير معروف';
        countMap[name] = (countMap[name] || 0) + 1;
        feeMap[name] = (feeMap[name] || 0) + (order.deliveryFee || 0);
      });
  
      const sortedCount = Object.entries(countMap)
        .map(([name, count]) => ({ name, count }))
        .sort((a, b) => b.count - a.count)
        .slice(0, 5);
  
      const sortedFees = Object.entries(feeMap)
        .map(([name, totalFee]) => ({ name, totalFee: parseFloat(totalFee.toFixed(3)) }))
        .sort((a, b) => b.totalFee - a.totalFee)
        .slice(0, 5);
  
      setTopDrivers(sortedCount);
      setTopDriverFees(sortedFees);
    };
  
    fetchTopDrivers();
  }, []);
  

  return (    
    <div className="restaurant-dashboard">
      <AdminSidebar />
      <div className="restaurant-dashboard-content">
        <AdminHeader adminName="تقارير السائقين" />


        <div className="report-chart-section">
  <h3>أكثر السائقين نشاطًا (حسب عدد التوصيلات)</h3>
  <ResponsiveContainer width="100%" height={300}>
    <BarChart data={topDrivers} layout="vertical" margin={{ left: 30 }}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis type="number" allowDecimals={false} />
      <YAxis dataKey="name" type="category" width={120} />
      <Tooltip />
      <Bar dataKey="count" fill="#ff9800" barSize={24} />
    </BarChart>
  </ResponsiveContainer>
</div>

<div className="report-chart-section">
  <h3>أكثر السائقين من حيث رسوم التوصيل (د.ك)</h3>
  <ResponsiveContainer width="100%" height={300}>
    <BarChart data={topDriverFees} layout="vertical" margin={{ left: 30 }}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis type="number" />
      <YAxis dataKey="name" type="category" width={120} />
      <Tooltip />
      <Bar dataKey="totalFee" fill="#4caf50" barSize={24} />
    </BarChart>
  </ResponsiveContainer>
</div>


        <div className="restaurant-dashboard-welcome">
          <h1>تقارير السائقين</h1>
          <p>هنا ستُعرض بيانات الأداء ونشاطات السائقين</p>
        </div>
      </div>
    </div>
  );
};

export default AdminReportsDrivers;
