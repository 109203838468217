import { Autocomplete } from '@react-google-maps/api';
import { useRef } from 'react';

const GooglePlacesAutocomplete = ({ onPlaceSelected }) => {
  const autocompleteRef = useRef(null);

  const handlePlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
    if (place.geometry) {
      const location = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      };
      onPlaceSelected({
        address: place.formatted_address,
        location
      });
    }
  };

  return (
    <Autocomplete
      onLoad={ref => (autocompleteRef.current = ref)}
      onPlaceChanged={handlePlaceChanged}
    >
      <input type="text" placeholder="ابحث عن عنوان" className="autocomplete-input" />
    </Autocomplete>
  );
};

export default GooglePlacesAutocomplete;
