// src/components/GoogleMapsProvider.js

import React, { createContext, useContext } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';

// إنشاء السياق
const GoogleMapsContext = createContext({ isLoaded: true, loadError: null });

// مزود الخرائط
export const GoogleMapsProvider = ({ children }) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ['places'], // مكتبة البحث التلقائي
  });

  return (
    <GoogleMapsContext.Provider value={{ isLoaded, loadError }}>
      {children}
    </GoogleMapsContext.Provider>
  );
};

// هوك للوصول إلى حالة التحميل
export const useGoogleMaps = () => useContext(GoogleMapsContext);
