import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "../firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db, functions } from "../firebase";
import { httpsCallable } from "firebase/functions";

// طلب إذن الإشعارات من المستخدم
export const requestNotificationPermission = async () => {
  if (!messaging) {
    console.warn('⚠️ Firebase Messaging غير متاح - تأكد من أنك على localhost أو HTTPS');
    return null;
  }

  try {
    console.log('🔹 طلب إذن الإشعارات...');
    const permission = await Notification.requestPermission();

    if (permission === 'granted') {
      console.log('✅ تم منح إذن الإشعارات');
      return getFCMToken();
    } else {
      console.warn('❌ تم رفض إذن الإشعارات');
      return null;
    }
  } catch (error) {
    console.error('❌ خطأ في طلب إذن الإشعارات:', error);
    return null;
  }
};

// الحصول على رمز FCM من Firebase
export const getFCMToken = async () => {
  if (!messaging) return null;

  try {
    const currentToken = await getToken(messaging, {
      vapidKey: 'BJh7mFmi44Du5fvsaayT-niXBM4SNrfrG3o8jl3Bf8g7TnUPzg4f3tuxBDfIWHv5zOudwfpi2a5axmL52TBWppw'
    });

    if (currentToken) {
      console.log('✅ تم الحصول على رمز FCM');
      return currentToken;
    } else {
      console.warn('⚠️ لم يتم الحصول على رمز FCM');
      return null;
    }
  } catch (error) {
    console.error('❌ خطأ في جلب رمز FCM:', error);
    return null;
  }
};

// الاستماع إلى الإشعارات الواردة عند عمل التطبيق
export const listenForMessages = () => {
  if (!messaging) return;

  onMessage(messaging, (payload) => {
    console.log('🔔 تم استلام إشعار:', payload);

    if (payload.notification) {
      const { title, body } = payload.notification;

      try {
        new Notification(title, {
          body,
          icon: "/logo192.png"
        });
      } catch (err) {
        console.error('❌ خطأ في عرض الإشعار:', err);
      }
    }
  });
};

// إرسال إشعار إلى السائق من Cloud Functions
// إرسال إشعار إلى السائق من Cloud Functions
export const sendNotification = async (recipientType, recipientId, message, fcmToken = null, orderId = null) => {
  try {
    // 1. حفظ الإشعار في قاعدة البيانات Firestore
    const notificationData = {
      recipientType,
      recipientId,
      message,
      isRead: false,
      timestamp: serverTimestamp(),
    };
    await addDoc(collection(db, "notifications"), notificationData);
    console.log('✅ تم حفظ الإشعار في قاعدة البيانات');

    // 2. إرسال إشعار FCM عبر دالة HTTPS
    if (fcmToken) {
      const response = await fetch("https://sendordernotification-f6l6kr6lva-uc.a.run.app", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          fcmToken,
          title: "طلب جديد",
          body: message,
          orderId: orderId || ""
        })
      });

      const result = await response.json();
      console.log("✅ نتيجة الإشعار من الدالة السحابية:", result);
    }

    return true;
  } catch (error) {
    console.error('❌ خطأ في إرسال الإشعار:', error);
    return false;
  }
};
