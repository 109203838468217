import React from 'react';
import './FinanceFilters.css';

const FinanceFilters = ({ filters, onChange }) => {
  return (
    <div className="finance-filters">
      <div className="filter-group">
        <label>الفترة:</label>
        <select
          value={filters.dateRange}
          onChange={(e) => onChange({ ...filters, dateRange: e.target.value })}
        >
          <option value="day">اليوم</option>
          <option value="month">هذا الشهر</option>
          <option value="year">هذه السنة</option>
        </select>
      </div>

      <div className="filter-group">
        <label>الاسم:</label>
        <input
          type="text"
          placeholder="ابحث بالاسم..."
          value={filters.name}
          onChange={(e) => onChange({ ...filters, name: e.target.value })}
        />
      </div>

      <div className="filter-group">
        <label>الحالة:</label>
        <select
          value={filters.status}
          onChange={(e) => onChange({ ...filters, status: e.target.value })}
        >
          <option value="all">الكل</option>
          <option value="تم التسليم ✅">تم التسليم</option>
          <option value="ملغي ❌">ملغي</option>
        </select>
      </div>
    </div>
  );
};

export default FinanceFilters;
