// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getMessaging } from "firebase/messaging";
import { getFunctions } from "firebase/functions"; // ✅ تمت إضافتها

const firebaseConfig = {
  apiKey: "AIzaSyAfpEXpKaRnVTfOdRpZaHBhoGCGlsHbVE8",
  authDomain: "atdelivery-9d139.firebaseapp.com",
  projectId: "atdelivery-9d139",
  databaseURL: "https://atdelivery-9d139.firebaseio.com",
  storageBucket: "atdelivery-9d139.appspot.com",
  messagingSenderId: "76043371440",
  appId: "1:76043371440:web:d2a57531269b997892cc75",
  vapidKey: "BJh7mFmi44Du5fvsaayT-niXBM4SNrfrG3o8jl3Bf8g7TnUPzg4f3tuxBDfIWHv5zOudwfpi2a5axmL52TBWppw"
};

// تهيئة Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const functions = getFunctions(app); // ✅ إضافة functions

// تهيئة Messaging
export let messaging = null;
try {
  if (
    typeof window !== 'undefined' && 
    typeof navigator !== 'undefined' &&
    (window.location.protocol === 'https:' || window.location.hostname === 'localhost') &&
    'serviceWorker' in navigator
  ) {
    messaging = getMessaging(app);
    console.log('✅ تم تهيئة Firebase Messaging بنجاح');
  }
} catch (error) {
  console.error('❌ خطأ في تهيئة Firebase Messaging:', error);
}

export default app;
